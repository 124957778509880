<template>
  <div class="home">
            <div class="rn">
            <header class="nb is-revealed">
                <div class="tcontainern">
                    <div class="n_">
                        <div class="nk">
                            <h1 class="ot">
                                <!--<a href="index.html"><img src="images/logo.svg" alt="Surface" width="32" height="32" /></a>-->
                                <!-- <img src="@/assets/img/home/respondi-ico.png" alt="Surface" width="32" height="32" /> -->
                                <img src="@/assets/img/respondi-logo.png" alt="Respondi logo" style="max-width:140px"/>
                            </h1>
                        </div>
                        <button id="th" class="th" aria-controls="primary-menu" aria-expanded="false">
                            <span class="sn">Menu</span> <span class="tp"><span class="td"></span></span>
                        </button>
                        <nav id="nj" class="nj">
                            <div class="nq">
                                <!-- <ul class="sr h re">
                                    <li><a href="https://respondi.app/MQccSvWZ">Exemplo 1</a></li>
                                    <li><a href="https://respondi.app/eM0ncjGP">Exemplo 2</a></li>
                                </ul> -->
                                <ul class="sr re main-nav">
                                    <li><a id="light-buttonn" href="#plans">Planos</a></li>
                                    <li><a id="light-buttonn" :href="app_url + '/blog'">Blog</a></li>
                                    <li><a id="light-buttonn" href="https://help.respondi.app">Ajuda</a></li>
                                    <!-- <li><a id="light-buttonn" :href="app_url + '/dash'">Entrar</a></li> -->
                                    <li class="mr16"><Btn type="outline" :href="app_url + '/dash'">Entrar</Btn></li>
                                    <li><Btn :href="app_url + '/signup'">Criar conta</Btn></li>
                                    <!-- <li><a class="tbuttonn fbuttonl gbuttony gbuttony sbuttono" :href="app_url + '/signup'">Criar conta</a></li> -->
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </header>
            <main class="nw">
                <section class="rb nx sq tillustration-section-n">
                    <div class="rcontaineri">
                        <div class="rm rd">
                            <div class="rg">
                                <h1 class="on oj -reveal-from--top is-revealed" data-reveal-delay="150">Crie formulários incríveis</h1>
                                <div class="scontainero">
                                    <p style="line-height:140%;" class="ot uu -reveal-from--top is-revealed" data-reveal-delay="300">Faça pesquisas, questionários, quiz ou qualquer tipo de formulário de forma rápida, fácil e acessível. <strong>Pague em real</strong>, não em dólar.</p>
                                    <div class="-reveal-from--top is-revealed" data-reveal-delay="450"><a class="tbuttonn fbuttonl" href="#plans">Comece grátis →</a></div>
                                </div>
                            </div>
                            <div class="ry -reveal-from--bottom is-revealed" data-reveal-delay="600"><a href="https://respondi.app/NNMni5MV/?ref=demo" target="_blank"><img class="id" src="@/assets/img/home/form-demo.gif" alt="Hero image" /></a></div>
                        </div>
                    </div>
                </section>
                <!-- <section class="ia nx">
                    <div class="tcontainern">
                        <div class="if rd s_ sj">
                            <ul class="sr">
                                <li class=""><img src="images/clients-01.svg" alt="Clients 01" /></li>
                                <li class="" data-reveal-delay="150"><img src="images/clients-02.svg" alt="Clients 02" /></li>
                                <li class="" data-reveal-delay="300"><img src="images/clients-03.svg" alt="Clients 03" /></li>
                                <li class="" data-reveal-delay="450"><img src="images/clients-04.svg" alt="Clients 04" /></li>
                                <li class="" data-reveal-delay="600"><img src="images/clients-05.svg" alt="Clients 05" /></li>
                            </ul>
                        </div>
                    </div>
                </section> -->

                <section class="rw nx sq">
                    <div class="tcontainern">
                        <div class="features-tiles-inner rd s_">
                            <div class="rv sq">
                                <div class="scontainero">
                                    <h2 class="on oj">Feijão com arroz bem feito</h2>
                                    <p class="ot">Um conjunto de ferramentas simples e poderosas, prontas para te ajudar a obter as respostas que você precisa.</p>
                                </div>
                            </div>
                            <div class="tiles-wrap">
                                <div class="ng" data-reveal-container=".tiles-wrap">
                                    <div class="ny">
                                        <div class="features-tiles-item-header">
                                            <div class="im oj"><img src="@/assets/img/home/feature-tile-icon-02.svg" alt="Feature tile icon 01" /></div>
                                        </div>
                                        <div class="features-tiles-item-content">
                                            <h4 class="on od">Vários formatos</h4>
                                            <p class="ot l">Perguntas em texto simples, multipla seleção, textos longos, estrelas/nota, links, e-mails, etc.</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="ng" data-reveal-container=".tiles-wrap" data-reveal-delay="400">
                                    <div class="ny">
                                        <div class="features-tiles-item-header">
                                            <div class="im oj"><img src="@/assets/img/home/feature-tile-icon-06.svg" alt="Feature tile icon 05" /></div>
                                        </div>
                                        <div class="features-tiles-item-content">                                            
                                            <h4 class="on od">Validações exclusivas</h4>
                                            <p class="ot l">Valide se um CPF ou CNPJ está correto. Você pode até pesquisar um endereço por CEP! <span class="new">Novo</span></p>
                                        </div>
                                    </div>
                                </div>

                                <div class="ng" data-reveal-container=".tiles-wrap" data-reveal-delay="100">
                                    <div class="ny">
                                        <div class="features-tiles-item-header">
                                            <div class="im oj"><img src="@/assets/img/home/feature-tile-icon-01.svg" alt="Feature tile icon 02" /></div>
                                        </div>
                                        <div class="features-tiles-item-content">
                                            <h4 class="on od">Exporte suas respostas</h4>
                                            <p class="ot l">Exporte seu dados para um arquivo .csv/Excel ou integre automaticamente com o Google Planilhas.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="ng" data-reveal-container=".tiles-wrap" data-reveal-delay="200">
                                    <div class="ny">
                                        <div class="features-tiles-item-header">
                                            <div class="im oj"><img src="@/assets/img/home/feature-tile-icon-03.svg" alt="Feature tile icon 03" /></div>
                                        </div>
                                        <div class="features-tiles-item-content">
                                            <h4 class="on od">Sua marca, suas cores</h4>
                                            <p class="ot l">Personalize seu formulário com seu logotipo, cores e imagens mesmo no plano grátis!</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="ng" data-reveal-container=".tiles-wrap" data-reveal-delay="300">
                                    <div class="ny">
                                        <div class="features-tiles-item-header">
                                            <div class="im oj"><img src="@/assets/img/home/feature-tile-icon-04.svg" alt="Feature tile icon 04" /></div>
                                        </div>
                                        <div class="features-tiles-item-content">
                                            <h4 class="on od">Atualização instantânea</h4>
                                            <p class="ot l">Não perca dados importantes. Nós salvamos tudo mesmo antes da seu cliente apertar "Enviar".</p>
                                        </div>
                                    </div>
                                </div>  
        
                                <div class="ng" data-reveal-container=".tiles-wrap" data-reveal-delay="500">
                                    <div class="ny">
                                        <div class="features-tiles-item-header">
                                            <div class="im oj"><img src="@/assets/img/home/feature-tile-icon-05.svg" alt="Feature tile icon 06" /></div>
                                        </div>
                                        <div class="features-tiles-item-content">
                                            <h4 class="on od">Feito no Brasil</h4>
                                            <p class="ot l">Pague em real brasileiro, sem taxas internacionais. Tenha um preço fixo, sem flutuação.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section class="rx nx sq">
                    <div class="tcontainern">
                        <div class="features-tabs-inner rd">
                            <div class="rv sq">
                                <div class="scontainero">
                                    <h2 class="on oj">O editor mais fácil que você já viu</h2>
                                    <p class="ot">Construa seu formulário em <strong>segundos</strong>. É sério. 60 segundos é tudo o que você precisa para criar seu primeiro formulário.</p>
                                </div>
                            </div>
                            
                            <div class="tabs">
                                 <!--<ul class="nn sr oi">
                                    <li class="nr tb" role="tab" aria-controls="tab-a">
                                        <div class="ig ob"><img src="images/features-tabs-icon-01.svg" alt="Tab icon 01" width="56" height="56" /></div>
                                        <div class="sc l">Formulários de inscrição</div>
                                    </li>
                                    <li class="nr" role="tab" aria-controls="tab-b">
                                        <div class="ig ob"><img src="images/features-tabs-icon-02.svg" alt="Tab icon 02" width="56" height="56" /></div>
                                        <div class="sc l">Internal Feedback</div>
                                    </li>
                                    <li class="nr" role="tab" aria-controls="tab-c">
                                        <div class="ig ob"><img src="images/features-tabs-icon-03.svg" alt="Tab icon 03" width="56" height="56" /></div>
                                        <div class="sc l">Internal Feedback</div>
                                    </li>
                                    <li class="nr" role="tab" aria-controls="tab-d">
                                        <div class="ig ob"><img src="images/features-tabs-icon-04.svg" alt="Tab icon 04" width="56" height="56" /></div>
                                        <div class="sc l">Internal Feedback</div>
                                    </li>
                                </ul>  -->
                                <div id="tab-a" class="ni tb" role="tabpanel"><img class="id" src="@/assets/img/home/respondi-editor.gif" alt="Features tabs image 01" /></div>
                                <!--<div id="tab-b" class="ni" role="tabpanel"><img class="id" src="images/features-tabs-image.png" alt="Features tabs image 02" width="896" height="504" /></div>
                                <div id="tab-c" class="ni" role="tabpanel"><img class="id" src="images/features-tabs-image.png" alt="Features tabs image 03" width="896" height="504" /></div>
                                <div id="tab-d" class="ni" role="tabpanel"><img class="id" src="images/features-tabs-image.png" alt="Features tabs image 04" width="896" height="504" /></div>-->
                            </div> 


                        </div>
                    </div>
                </section>

                <!-- Integrações -->
                <section class="rx nx sq">
                    <div class="tcontainern">
                        <div class="features-tabs-inner rd">
                            <div class="rv sq">
                                <div class="scontainero">
                                    <h2 class="on oj">Milhares de integrações</h2>
                                    <p class="ot">Conecte seu formulário com todos os Apps que você já usa no dia a dia. Não perca nenhuma resposta através dos Webhooks!</p>
                                </div>
                            </div>                       
                                <div id="tab-a" class="ni tb" role="tabpanel"><img class="id" src="@/assets/img/home/respondi-integracoes.png" alt="Features tabs image 01" /></div>
                        </div>
                    </div>
                </section>

                <!-- <section class="it nx ih sj">
                    <div class="tcontainern">
                        <div class="testimonial-inner rd s_">
                            <div class="rv sq">
                                <div class="scontainero">
                                    <h2 class="on oj">Customer testimonials</h2>
                                    <p class="ot">Vitae aliquet nec ullamcorper sit amet risus nullam eget felis semper quis lectus nulla at volutpat diam ut venenatis tellus—in ornare.</p>
                                </div>
                            </div>
                            <div class="tiles-wrap">
                                <div class="ng" data-reveal-container=".tiles-wrap">
                                    <div class="ny id">
                                        <div class="testimonial-item-header oj">
                                            <div class="i_"><img src="images/testimonial-image-01.jpg" alt="Testimonial image 01" width="48" height="48" /></div>
                                        </div>
                                        <div class="in">
                                            <p class="l oi">
                                                — Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                                                mollit anim id est laborum cillum dolore eu fugiat culpa qui officia deserunt mollit anim id est laborum.
                                            </p>
                                        </div>
                                        <div class="iq c sa us oi s_">
                                            <span class="ij">Kendra Kinson </span><span class="sp">/ </span><span class="iz"><a href="#">AppName</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="ng" data-reveal-container=".tiles-wrap" data-reveal-delay="200">
                                    <div class="ny id">
                                        <div class="testimonial-item-header oj">
                                            <div class="i_"><img src="images/testimonial-image-02.jpg" alt="Testimonial image 02" width="48" height="48" /></div>
                                        </div>
                                        <div class="in">
                                            <p class="l oi">
                                                — Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                                                mollit anim id est laborum cillum dolore eu fugiat culpa qui officia deserunt mollit anim id est laborum.
                                            </p>
                                        </div>
                                        <div class="iq c sa us oi s_">
                                            <span class="ij">Emilia Jovic </span><span class="sp">/ </span><span class="iz"><a href="#">AppName</a></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="ng" data-reveal-container=".tiles-wrap" data-reveal-delay="400">
                                    <div class="ny id">
                                        <div class="testimonial-item-header oj">
                                            <div class="i_"><img src="images/testimonial-image-03.jpg" alt="Testimonial image 03" width="48" height="48" /></div>
                                        </div>
                                        <div class="in">
                                            <p class="l oi">
                                                — Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt
                                                mollit anim id est laborum cillum dolore eu fugiat culpa qui officia deserunt mollit anim id est laborum.
                                            </p>
                                        </div>
                                        <div class="iq c sa us oi s_">
                                            <span class="ij">Mark Walker </span><span class="sp">/ </span><span class="iz"><a href="#">AppName</a></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> -->
                <section class="rk nx iy" id="plans">
                    <div class="">
                        <div class="pricing-inner rd">
                            <div class="rv sq">
                                <div class="scontainero">
                                    <h2 class="on oj">Sim, nós aceitamos boleto! ;)</h2>
                                    <p class="ot">Cansado de pagar IOF e nunca saber quanto a mensalidade do seu serviço vai custar no fim do mês? Nós também.</p>
                                </div>
                            </div>

                            <section class="price-table">
                                <PriceTable />
                            </section>
   
                            <div class="tiles-wrap" style="display: none">
                                <div class="ng">
                                    <div class="ny id">
                                        <div class="r_">
                                            <div class="ib al un">   
                                                <div class="pricing-item-price">                                            
                                                    <span class="iw o">R$ </span><span class="pricing-item-price-amount i">0,00</span><span class="pricing-item-price-after l">/mês</span>
                                                </div> 
                                                <div class="c sp">Plano grátis</div>
                                            </div> 
                                            <div class="pricing-item-features mb-40">
                                                <div class="ix f c sc un">Você recebe</div>
                                                <ul class="rj sr c uu">
                                                    <li class="ik">100 respostas por mês</li>
                                                    <li class="ik">Formulários ilimitados</li>
                                                    <li class="ik">Páginas personalizadas</li>
                                                    <li class="ik">Sua cores, seu logotipo</li>
                                                    <li class="ik">Suporte em português</li>
                                                    <li>Busca de CEP</li>
                                                    <li>Redirecione ao final</li>
                                                    <li>Baixe um arquivo ao final</li>                                                    
                                                    <li>Não exibe a nossa marca</li>
                                                    <li>Validação de CPF</li>
                                                    <li>Validação de CNPJ</li>
                                                    <li>Google Analytics</li>
                                                    <li>Pixel de conversão</li>
                                                    <li>Multiplos usuários</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="pricing-item-cta od"><a class="tbuttonn fbuttonl pbuttond" :href="app_url + '/signup'">Começar grátis</a></div>
                                    </div>
                                </div>
                                <div class="ng" data-reveal-delay="200">
                                    <div class="ny id">
                                        <div class="r_">
                                            <div class="ib al un">
                                                <div class="pricing-item-price" >
                                                    <span class="iw o">R$ </span><span class="pricing-item-price-amount i">57,00</span><span class="pricing-item-price-after l">/mês</span>
                                                </div>
                                                <div class="c sp">Plano Solo</div>
                                            </div>
                                            <div class="pricing-item-features mb-40">
                                                <div class="ix f c sc un">O que você recebe</div>
                                                <ul class="rj sr c uu">
                                                    <li class="ik">1.000 respostas por mês</li>
                                                    <li class="ik">Formulários ilimitados</li>
                                                    <li class="ik">Páginas personalizadas</li>
                                                    <li class="ik">Sua cores, seu logotipo</li>
                                                    <li class="ik">Suporte em português</li>
                                                    <li class="ik">Busca de CEP <span class="new">Novo</span></li>
                                                    <li class="ik">Redirecione ao final</li>
                                                    <li class="ik">Baixe um arquivo ao final</li>
                                                    <li>Não exibe a nossa marca</li>
                                                    <li>Validação de CPF</li>
                                                    <li>Validação de CNPJ</li>
                                                    <li>Google Analytics</li>
                                                    <li>Pixel de conversão</li>
                                                    <li>Multiplos usuários</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- <div class="pricing-item-cta od"><a class="tbuttonn fbuttonl pbuttond" href="https://respondi.app/eM0ncjGP">Começar →</a></div> -->
                                        <div class="pricing-item-cta od">
                                            <a class="tbuttonn fbuttonl pbuttond" :href="app_url + '/signup/?checkout=true&plan=solo'">Assinar</a>
                                            <!-- <button                                                
                                                id="checkout-button-price_1J7CZgCfegz17XguCotSayTM"
                                                role="link"
                                                type="button"
                                                class="tbuttonn fbuttonl pbuttond"
                                                >
                                                Assinar
                                            </button> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="ng" data-reveal-delay="400">
                                    <div class="ny id">
                                        <div class="r_">
                                            <div class="ib al un">
                                                <div class="pricing-item-price">
                                                    <span class="iw o">R$ </span><span class="pricing-item-price-amount i">87,00</span><span class="pricing-item-price-after l">/mês</span>
                                                </div>
                                                <div class="c sp">Plano Pro</div>
                                            </div>
                                            <div class="pricing-item-features mb-40">
                                                <div class="ix f c sc un">O que você recebe</div>
                                                <ul class="rj sr c uu">
                                                    <li class="ik">5.000 respostas por mês</li>
                                                    <li class="ik">Formulários ilimitados</li>
                                                    <li class="ik">Páginas personalizadas</li>
                                                    <li class="ik">Sua cores, seu logotipo</li>
                                                    <li class="ik">Suporte em português</li>
                                                    <li class="ik">Busca de CEP <span class="new">Novo</span></li>
                                                    <li class="ik">Redirecione ao final</li>
                                                    <li class="ik">Baixe um arquivo ao final <span class="new">Novo</span></li>
                                                    <li class="ik">Não exibe a nossa marca</li>
                                                    <li class="ik">Validação de CPF <span class="new">Novo</span></li>
                                                    <li class="ik">Validação de CNPJ <span class="new">Novo</span></li>
                                                    <li class="ik">Google Analytics</li>
                                                    <li class="ik">Pixel de conversão</li>
                                                    <li class="ik">Multiplos usuários <span class="soon">Em breve</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- <div class="pricing-item-cta od"><a class="tbuttonn fbuttonl pbuttond" href="https://respondi.app/eM0ncjGP">Começar →</a></div> -->
                                        <div class="pricing-item-cta od">
                                            <a class="tbuttonn fbuttonl pbuttond" :href="app_url + '/signup/?checkout=true&plan=pro'">Assinar</a>
                                            <!-- <button                                                
                                                id="checkout-button-price_1J7CZ0Cfegz17Xgu5wp5oCrK"
                                                role="link"
                                                type="button"
                                                class="tbuttonn fbuttonl pbuttond"
                                                >
                                                Assinar
                                            </button> -->
                                        </div>
                                    </div>
                                </div>
                            </div>


                        <div class="boleto" style="margin: 60px auto 30px auto; color:#fff; text-align:center; font-size:18px;">
                            <a style="color:#fff; text-decoration:none;" href="https://respondi.app/KUNqz58b">Quero pagar via <strong style="text-decoration:underline;">boleto bancário</strong>.</a>                        
                        </div>
                        </div>
                    </div>
                </section>
                <!-- <section class="is nx ft sk r">
                    <div class="tcontainern">
                        <div class="st rd iu">
                            <div class="io"><h3 class="ot">For previewing layouts and visual?</h3></div>
                            <div class="cta-action">
                                <div class="tu">
                                    <label for="newsletter" class="x sn">Subscribe</label> <input id="newsletter" class="j" type="text" placeholder="Your best email" />
                                    <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg"><path d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z" fill="#3D5AFE"></path></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> -->









            </main>
            <footer class="rr ft r">
                <div class="tcontainern">
                    <div class="ri">
                        <!-- <div class="rs rh h">
                            <div class="nk">
                                <a href="index.html"><img src="images/logo.svg" alt="Surface" width="32" height="32" /></a>
                            </div>
                            <div class="rl">
                                <ul class="sr">
                                    <li>
                                        <a href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <title>Facebook</title>
                                                <path d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z"></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <title>Twitter</title>
                                                <path
                                                    d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z"
                                                ></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                                                <title>Instagram</title>
                                                <g>
                                                    <circle cx="12.145" cy="3.892" r="1"></circle>
                                                    <path d="M8 12c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z"></path>
                                                    <path
                                                        d="M12 16H4c-2.056 0-4-1.944-4-4V4c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zM4 2c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2V4c0-.935-1.065-2-2-2H4z"
                                                    ></path>
                                                </g>
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div> -->
                        <div class="ro rh h rp">
                            <nav class="rc">
                                <ul class="sr">
                                    <li><a href="#" onclick="Beacon('open'); Beacon('navigate', '/ask/message')">Entre em contato</a></li>
                                    <li><a href="/typeform-ou-respondi">Respondi ou Typeform?</a></li>
                                    <!--<li><a href="#">FAQ's</a></li>
                                    <li><a href="#">Support</a></li>-->
                                </ul>
                            </nav>
                            <div class="footer-copyright">©2022 respondi.app</div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>

        <div id="error-message"></div>

  </div>
</template>


<script>
import Btn from '../components/form/Btn.vue'
import PriceTable from '../components/PriceTable.vue'
import {UserSourceMixin} from '@/mixins/UserSourceMixin.js'


export default {
  name: 'Home',
    metaInfo: {
    title: 'Respondi | Crie formulários e pesquisas online rapidamente',
  },

  mixins: [ UserSourceMixin ],
  components: { PriceTable, Btn },

  data() {
      return {
          query: null
      }
  },
  computed: {
      app_url() {
          if(process.env.URL) return process.env.URL
          if(process.env.VUE_APP_URL) return process.env.VUE_APP_URL
          else return null
      }

  },
  mounted: function(){
    //   this.stripe();
      this.getUserSource(this.$route.query)
  },

}
</script>

<style lang="css" scoped>
.main-nav li a{
    padding-left: 16px !important;
    padding-right: 16px !important;
}

@import url("https://fonts.googleapis.com/css?family=Heebo:400,500,700&display=swap");
html {
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    scroll-behavior: smooth;
     
}
body {
    margin: 0;
}
article,
aside,
footer,
header,
nav,
section {
    display: block;
}
h1 {
    font-size: 2em;
    margin: 0.67em 0;
}
figcaption,
figure,
main {
    display: block;
}
figure {
    margin: 1em 40px;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
pre {
    font-family: monospace, monospace;
    font-size: 1em;
}
a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
}
b,
strong {
    font-weight: inherit;
}
b,
strong {
    font-weight: bolder;
}
code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}
dfn {
    font-style: italic;
}
mark {
    background-color: #ff0;
    color: #000;
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
audio,
video {
    display: inline-block;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
img {
    border-style: none;
}
svg:not(:root) {
    overflow: hidden;
}
button,
input,
optgroup,
select,
textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}
button,
input {
    overflow: visible;
}
button,
select {
    text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}
fieldset {
    padding: 0.35em 0.75em 0.625em;
}
legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}
progress {
    display: inline-block;
    vertical-align: baseline;
}
textarea {
    overflow: auto;
}
[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}
::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}
details,
menu {
    display: block;
}
summary {
    display: list-item;
}
canvas {
    display: inline-block;
}
template {
    display: none;
}
[hidden] {
    display: none;
}
@media (min-width: 641px) {
    .t [class*="reveal-"] {
        opacity: 0;
        will-change: opacity, transform;
    }
    .t .reveal-from-top {
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
    .t .reveal-from-bottom {
        -webkit-transform: translateY(20px);
        transform: translateY(20px);
    }
    .t .reveal-from-left {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
    }
    .t .reveal-from-right {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }
    .t .reveal-scale-up {
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
    }
    .t .reveal-scale-down {
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }
    .t .reveal-rotate-from-left {
        -webkit-transform: perspective(1000px) rotateY(-45deg);
        transform: perspective(1000px) rotateY(-45deg);
    }
    .t .reveal-rotate-from-right {
        -webkit-transform: perspective(1000px) rotateY(45deg);
        transform: perspective(1000px) rotateY(45deg);
    }
    .t.n [class*="reveal-"] {
        -webkit-transition: opacity 0.6s ease, -webkit-transform 0.6s ease;
        transition: opacity 0.6s ease, -webkit-transform 0.6s ease;
        transition: opacity 0.6s ease, transform 0.6s ease;
        transition: opacity 0.6s ease, transform 0.6s ease, -webkit-transform 0.6s ease;
    }
    .t.n [class*="reveal-"].is-revealed {
        opacity: 1;
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}
html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}
body {
    background: #fff;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
hr {
    border: 0;
    display: block;
    height: 1px;
    background: #e9ecf8;
    margin-top: 24px;
    margin-bottom: 24px;
}
ul,
ol {
    margin-top: 0;
    padding-left: 24px;
}
ul:not(:last-child),
ol:not(:last-child) {
    margin-bottom: 24px;
}
ul {
    list-style: disc;
}
ol {
    list-style: decimal;
}
li:not(:last-child) {
    margin-bottom: 12px;
}
li > ul,
li > ol {
    margin-top: 12px;
    margin-bottom: 0;
}
dl {
    margin-top: 0;
    margin-bottom: 24px;
}
dt {
    font-weight: 700;
}
dd {
    margin-left: 24px;
    margin-bottom: 24px;
}
img {
    border-radius: 2px;
}
img,
svg,
video {
    display: block;
    height: auto;
    max-width: 100%;
}
figure {
    margin: 48px 0;
}
figcaption {
    padding: 8px 0;
}
table {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    border-collapse: collapse;
    margin-bottom: 24px;
    width: 100%;
}
tr {
    border-bottom: 1px solid #e9ecf8;
}
th {
    font-weight: 700;
    text-align: left;
}
th,
td {
    padding: 8px 16px;
}
th:first-child,
td:first-child {
    padding-left: 0;
}
th:last-child,
td:last-child {
    padding-right: 0;
}
.r hr {
    display: block;
    height: 1px;
    background: rgba(87, 94, 134, 0.24);
}
.r tr {
    border-bottom: 1px solid rgba(87, 94, 134, 0.24);
}
html {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.1px;
}
body {
    color: #575e86;
    font-size: 1rem;
}
body,
button,
input,
select,
textarea {
    font-family: "Heebo", sans-serif;
    font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6,
.i,
.s,
.o,
.u,
.a,
.f {
    font-weight: 700;
    clear: both;
    color: #2e3458;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
h1,
.i {
    font-size: 38px;
    line-height: 48px;
}
h2,
.s {
    font-size: 32px;
    line-height: 42px;
}
h3,
.o {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.1px;
}
h4,
.u {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.1px;
}
h5,
.a {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.1px;
}
h6,
.f,
small,
.l {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.1px;
}
.c {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
}
.h {
    font-size: 14px;
    line-height: 22px;
}
figcaption {
    font-size: 14px;
    line-height: 22px;
}
a:not(.tbuttonn) {
    color: #2e3458;
    text-decoration: underline;
}
a:not(.tbuttonn):hover,
a:not(.tbuttonn):active {
    outline: 0;
    text-decoration: none;
}
a.rbuttoni {
    color: #575e86;
}
a.rbuttoni:hover,
a.rbuttoni:active {
    color: #2e3458;
}
a.p {
    color: #3d5afe;
}
a.p:hover,
a.p:active {
    color: #7085fe;
}
h1,
.i {
    margin-top: 48px;
    margin-bottom: 32px;
}
h2,
.s {
    margin-top: 48px;
    margin-bottom: 32px;
}
h3,
.o {
    margin-top: 36px;
    margin-bottom: 24px;
}
h4,
h5,
h6,
.u,
.a,
.f {
    margin-top: 24px;
    margin-bottom: 24px;
}
p {
    margin-top: 0;
    margin-bottom: 24px;
}
dfn,
cite,
em,
i {
    font-style: italic;
}
blockquote {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.1px;
    margin-top: 24px;
    margin-bottom: 24px;
    margin-left: 24px;
}
blockquote::before {
    content: "\201C";
}
blockquote::after {
    content: "\201D";
}
blockquote p {
    display: inline;
}
address {
    border-width: 1px 0;
    border-style: solid;
    border-color: #e9ecf8;
    padding: 24px 0;
    margin: 0 0 24px;
}
pre,
pre h1,
pre h2,
pre h3,
pre h4,
pre h5,
pre h6,
pre .i,
pre .s,
pre .o,
pre .u,
pre .a,
pre .f {
    font-family: "Courier 10 Pitch", Courier, monospace;
}
pre,
code,
kbd,
tt,
var {
    background: #f9fafc;
}
pre {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    max-width: 100%;
    overflow: auto;
    padding: 24px 24px;
    margin-top: 24px;
    margin-bottom: 24px;
}
code,
kbd,
tt,
var {
    font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    padding: 2px 4px;
}
abbr,
acronym {
    cursor: help;
}
mark,
ins {
    text-decoration: none;
}
b,
strong {
    font-weight: 700;
}
.r {
    color: #9298b8;
}
.r a:not(.tbuttonn) {
    color: #e9ecf8;
    text-decoration: underline;
}
.r a:not(.tbuttonn):hover,
.r a:not(.tbuttonn):active {
    outline: 0;
    text-decoration: none;
}
.r a.rbuttoni {
    color: #9298b8;
}
.r a.rbuttoni:hover,
.r a.rbuttoni:active {
    color: #e9ecf8;
}
.r h1,
.r h2,
.r h3,
.r h4,
.r h5,
.r h6,
.r .i,
.r .s,
.r .o,
.r .u,
.r .a,
.r .f {
    color: #fff;
}
.r address {
    border-color: rgba(87, 94, 134, 0.24);
}
.r pre,
.r code,
.r kbd,
.r tt,
.r var {
    background: #292e4e;
}
@media (max-width: 640px) {
    .d {
        font-size: 38px;
        line-height: 48px;
    }
    .v {
        font-size: 32px;
        line-height: 42px;
    }
    .m {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.1px;
    }
    .g {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.1px;
    }
    .y {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.1px;
    }
    .b {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.1px;
    }
}
@media (min-width: 641px) {
    h1,
    .i {
        font-size: 48px;
        line-height: 58px;
    }
    h2,
    .s {
        font-size: 38px;
        line-height: 48px;
    }
    h3,
    .o {
        font-size: 32px;
        line-height: 42px;
    }
}
.tcontainern,
.rcontaineri,
.scontainero {
    width: 100%;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
}
@media (min-width: 481px) {
    .tcontainern,
    .rcontaineri,
    .scontainero {
        padding-left: 24px;
        padding-right: 24px;
    }
}
.tcontainern {
    max-width: 1128px;
}
.rcontaineri {
    max-width: 944px;
}
.scontainero {
    max-width: 668px;
}
[class*="container"] [class*="container"] {
    padding-left: 0;
    padding-right: 0;
}
[class*="container"] .rcontaineri {
    max-width: 896px;
}
[class*="container"] .scontainero {
    max-width: 620px;
}
.tbuttonn {
    display: -webkit-inline-box;
    display: inline-flex;
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
    padding: 12px 31px;
    height: 48px;
    text-decoration: none !important;
    text-transform: uppercase;
    color: #575e86;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0);
    border-radius: 2px;
    cursor: pointer;
    -webkit-box-pack: center;
    justify-content: center;
    text-align: center;
    letter-spacing: inherit;
    white-space: nowrap;
    -webkit-transition: background 0.15s ease;
    transition: background 0.15s ease;
}
.tbuttonn:active {
    outline: 0;
}
.tbuttonn:hover {
    background-color: #f9fafc;
}
.tbuttonn.w {
    position: relative;
    color: transparent !important;
    pointer-events: none;
}
.tbuttonn.w::after {
    content: "";
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    margin-left: -11px;
    margin-top: -11px;
    top: 50%;
    left: 50%;
    border: 2px solid #575e86;
    border-radius: 50%;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    z-index: 1;
    -webkit-animation: button-loading 0.6s infinite linear;
    animation: button-loading 0.6s infinite linear;
}
.tbuttonn[disabled] {
    cursor: not-allowed;
    color: #9298b8;
    background-color: #e9ecf8 !important;
}
.tbuttonn[disabled].w::after {
    border-color: #9298b8;
}
.sbuttono {
    padding: 6px 23px;
    height: 36px;
}
.ubuttona {
    color: #fff;
    background-color: #2e3458;
}
.ubuttona:hover {
    background-color: #333a62;
}
.ubuttona.w::after {
    border-color: #fff;
}
.fbuttonl {
    color: #fff;
    background-color: #3d5afe;
}
.fbuttonl:hover {
    background-color: #4c67fe;
}
.fbuttonl.w::after {
    border-color: #fff;
}
.cbuttonh {
    color: #fff;
    background-color: #00c2a9;
}
.cbuttonh:hover {
    background-color: #00d1b6;
}
.cbuttonh.w::after {
    border-color: #fff;
}
.pbuttond {
    display: -webkit-box;
    display: flex;
    width: 100%;
}
.vbuttonm {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
    margin-right: -12px;
    margin-left: -12px;
    margin-top: -12px;
}
.vbuttonm:last-of-type {
    margin-bottom: -12px;
}
.vbuttonm:not(:last-of-type) {
    margin-bottom: 12px;
}
.vbuttonm > [class*="button"] {
    margin: 12px;
}
@media (max-width: 640px) {
    .gbuttony {
        width: 100%;
        max-width: 280px;
    }
}
@-webkit-keyframes button-loading {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes button-loading {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
fieldset {
    padding: 0;
    margin: 0;
    border: 0;
}
.x,
.k,
._ {
    color: #575e86;
    font-weight: 500;
}
.x {
    font-size: 14px;
    line-height: 22px;
}
.j,
.q,
.z input,
.te input {
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #e9ecf8;
}
.j:hover,
.q:hover,
.z input:hover,
.te input:hover {
    border-color: #d6dbf2;
}
.j:active,
.j:focus,
.q:active,
.q:focus,
.z input:active,
.z input:focus,
.te input:active,
.te input:focus {
    outline: none;
    border-color: #9298b8;
}
.j[disabled],
.q[disabled],
.z input[disabled],
.te input[disabled] {
    cursor: not-allowed;
    background-color: #f9fafc;
    border-color: #f9fafc;
}
.j.tt,
.q.tt,
.z input.tt,
.te input.tt {
    border-color: #ff6f66;
}
.j.tn,
.q.tn,
.z input.tn,
.te input.tn {
    border-color: #ffcd6e;
}
.j.tr,
.q.tr,
.z input.tr,
.te input.tr {
    border-color: #00c2a9;
}
.j,
.q {
    display: block;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    padding: 11px 15px;
    height: 48px;
    border-radius: 0;
    color: #2e3458;
    box-shadow: none;
    max-width: 100%;
    width: 100%;
}
.j::-webkit-input-placeholder {
    color: #9298b8;
}
.j::-moz-placeholder {
    color: #9298b8;
}
.j:-ms-input-placeholder {
    color: #9298b8;
}
.j::-ms-input-placeholder {
    color: #9298b8;
}
.j::placeholder {
    color: #9298b8;
}
.j::-ms-input-placeholder {
    color: #9298b8;
}
textarea.j {
    height: auto;
    resize: vertical;
}
.q {
    padding-right: 46px;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%2011.4L2.6%206%204%204.6l4%204%204-4L13.4%206%208%2011.4z%22%20fill%3D%22%23575E86%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
    background-position: right 15px center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
}
.ti {
    padding-right: 38px;
    background-position: right 11px center;
}
.ti,
.ts {
    padding: 5px 11px;
    height: 36px;
}
.to,
.tu {
    position: relative;
}
.to svg,
.tu svg {
    pointer-events: none;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.to .j {
    padding-left: 46px;
}
.to .j + svg {
    left: 15px;
}
.to .ts {
    padding-left: 38px;
}
.to .ts + svg {
    left: 11px;
}
.tu .j {
    padding-right: 46px;
}
.tu .j + svg {
    right: 15px;
}
.tu .ts {
    padding-right: 38px;
}
.tu .ts + svg {
    right: 11px;
}
.z,
.te,
.k {
    cursor: pointer;
}
.z,
.te,
.k,
._ {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
}
.z,
.te {
    color: #9298b8;
}
.z,
.te {
    position: relative;
    padding-left: 24px;
}
.z input,
.te input {
    position: absolute;
    left: 0;
    top: 3px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}
.z input:checked,
.te input:checked {
    background-color: #3d5afe !important;
    border-color: #3d5afe !important;
    background-position: 50%;
}
.z input {
    border-radius: 2px;
}
.z input:checked {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+IDxwYXRoIGQ9Ik0xMS40IDVMMTAgMy42bC00IDQtMi0yTDIuNiA3IDYgMTAuNHoiIGZpbGw9IiNGRkYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
}
.te input {
    border-radius: 50%;
}
.te input:checked {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBmaWxsPSIjRkZGIiBjeD0iNyIgY3k9IjciIHI9IjMiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
}
.k {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.k input {
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    position: absolute;
    width: 1px;
}
.k .ta {
    display: block;
    position: relative;
    min-width: 60px;
    width: 60px;
    height: 32px;
    border-radius: 16px;
    background: #9298b8;
}
.k .ta::before {
    content: "";
    display: block;
    position: absolute;
    top: 2px;
    left: 2px;
    width: 28px;
    height: 28px;
    border-radius: 14px;
    background: #fff;
    -webkit-transition: all 0.15s ease-out;
    transition: all 0.15s ease-out;
}
.k input:checked + .ta {
    background: #3d5afe;
}
.k input:checked + .ta::before {
    left: 30px;
}
.k > span:not(.ta) {
    -webkit-box-ordinal-group: 0;
    order: -1;
    margin-right: 12px;
}
.k > span:not(.ta):last-child {
    -webkit-box-ordinal-group: initial;
    order: initial;
    margin-left: 12px;
    margin-right: 0;
}
._ > span {
    display: block;
}
._ input[type="range"] {
    -moz-appearance: none;
    -webkit-appearance: none;
    background: #e9ecf8;
    border-radius: 3px;
    height: 6px;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px;
    outline: none;
}
._ input[type="range"]::-webkit-slider-thumb {
    appearance: none;
    -webkit-appearance: none;
    background-color: #3d5afe;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: 36px;
    width: 36px;
}
._ input[type="range"]::-moz-range-thumb {
    background-color: #3d5afe;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 36px;
    width: 36px;
}
._ input[type="range"]::-ms-thumb {
    background-color: #3d5afe;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: 36px;
    width: 36px;
}
._ input[type="range"]::-moz-focus-outer {
    border: 0;
}
.r .q {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%2011.4L2.6%206%204%204.6l4%204%204-4L13.4%206%208%2011.4z%22%20fill%3D%22%239298B8%22%20fill-rule%3D%22evenodd%22%2F%3E%3C%2Fsvg%3E");
}
.r .z input:checked,
.r .te input:checked {
    /* background-color: !important;
    border-color: !important; */
}
.r ._ input[type="range"]::-webkit-slider-thumb {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
}
.r ._ input[type="range"]::-moz-range-thumb {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
}
.r ._ input[type="range"]::-ms-thumb {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2212%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M8%20.5v7L12%204zM0%204l4%203.5v-7z%22%20fill%3D%22%23fff%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
}
.tf {
    display: -webkit-box;
    display: flex;
}
.tf .j {
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 1;
    margin-right: 12px;
}
.tf .tbuttonn {
    padding-left: 24px;
    padding-right: 24px;
    flex-shrink: 0;
}
.tl {
    font-size: 14px;
    line-height: 22px;
    margin-top: 8px;
}
@media (max-width: 640px) {
    .tc .j {
        margin-bottom: 12px;
    }
    .tc .tbuttonn {
        width: 100%;
    }
}
@media (min-width: 641px) {
    .tc {
        display: -webkit-box;
        display: flex;
    }
    .tc .j {
        -webkit-box-flex: 1;
        flex-grow: 1;
        flex-shrink: 1;
        margin-right: 12px;
    }
    .tc .tbuttonn {
        padding-left: 24px;
        padding-right: 24px;
        flex-shrink: 0;
    }
}
.th {
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    cursor: pointer;
}
.tp,
.td {
    display: block;
    pointer-events: none;
}
.tp {
    position: relative;
    width: 24px;
    height: 24px;
}
.td,
.td::before,
.td::after {
    width: 24px;
    height: 2px;
    position: absolute;
    background: #575e86;
}
.r .td,
.r .td::before,
.r .td::after {
    background: #f9fafc;
}
.td {
    top: 50%;
    margin-top: -1px;
    -webkit-transition-duration: 0.22s;
    transition-duration: 0.22s;
    -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.td::before,
.td::after {
    content: "";
    display: block;
}
.td::before {
    top: -7px;
    -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.td::after {
    bottom: -7px;
    -webkit-transition: bottom 0.1s 0.25s ease-in, width 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, width 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in;
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), width 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.tv .td {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
    -webkit-transition-delay: 0.12s;
    transition-delay: 0.12s;
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.tv .td::before {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.tv .td::after {
    width: 24px;
    bottom: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transition: bottom 0.1s ease-out, width 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, width 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out;
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), width 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.tm li {
    position: relative;
}
.tm li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 1px;
    background: #e9ecf8;
}
.tm li:last-of-type {
    position: relative;
}
.tm li:last-of-type::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 1px;
    background: #e9ecf8;
}
.r .tm li {
    position: relative;
}
.r .tm li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 1px;
    background: rgba(87, 94, 134, 0.24);
}
.r .tm li:last-of-type {
    position: relative;
}
.r .tm li:last-of-type::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 1px;
    background: rgba(87, 94, 134, 0.24);
}
.tg {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
    cursor: pointer;
}
.tg span {
    width: calc(100% - (16px * 2));
}
.ty {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: max-height 0.15s ease-in-out, opacity 0.15s;
    transition: max-height 0.15s ease-in-out, opacity 0.15s;
}
.tb .ty {
    opacity: 1;
}
.ty p:last-child {
    margin-bottom: 24px;
}
.tw {
    position: relative;
    width: 16px;
    height: 16px;
}
.tw::before,
.tw::after {
    content: "";
    position: absolute;
    background: #3d5afe;
    -webkit-transition: -webkit-transform 0.25s ease-out;
    transition: -webkit-transform 0.25s ease-out;
    transition: transform 0.25s ease-out;
    transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}
.r .tw::before,
.r .tw::after {
    background: #f9fafc;
}
.tw::before {
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    margin-left: -1px;
}
.tw::after {
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    margin-top: -1px;
}
.tb .tw {
    cursor: pointer;
}
.tb .tw::before {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.tb .tw::after {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.tx,
.tx::before {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}
.tx {
    display: none;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    overflow: hidden;
    position: fixed;
    z-index: 40;
}
.tx.tk .t_ {
    padding: 0;
    max-width: 1024px;
}
.tx::before,
.tx .t_ {
    display: none;
}
.tx::before {
    content: "";
    position: absolute;
    background-color: rgba(46, 52, 88, 0.24);
}
.tx.tb {
    display: -webkit-box;
    display: flex;
}
.tx.tb::before,
.tx.tb .t_ {
    display: block;
}
.tx.tb .t_ {
    -webkit-animation: slideUpInModal 0.15s ease-in-out both;
    animation: slideUpInModal 0.15s ease-in-out both;
}
.tx.tb::before {
    -webkit-animation: slideUpInModalBg 0.15s ease-in-out both;
    animation: slideUpInModalBg 0.15s ease-in-out both;
}
.t_ {
    max-height: calc(100vh - 16px * 2);
    overflow: auto;
    position: relative;
    width: calc(100% - (16px * 2));
    max-width: 480px;
    margin-left: 16px;
    margin-right: 16px;
    background: #fff;
}
.tj {
    padding: 40px 16px;
}
.tq {
    background: transparent;
    border: 0;
    outline: 0;
    padding: 0;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 16px;
    height: 16px;
}
.tq::before,
.tq::after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -1px;
    margin-left: -8px;
    width: 16px;
    height: 2px;
    background: #9298b8;
}
.tq::before {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}
.tq::after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.tq:hover::before,
.tq:hover::after {
    background: #575e86;
}
@media (min-width: 641px) {
    .t_ {
        margin: 0 auto;
        max-height: calc(100vh - 48px * 2);
    }
    .tj {
        padding-left: 32px;
        padding-right: 32px;
    }
}
@-webkit-keyframes slideUpInModal {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-24px);
        transform: translateY(-24px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes slideUpInModal {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-24px);
        transform: translateY(-24px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@-webkit-keyframes slideUpInModalBg {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes slideUpInModalBg {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.tz.w:not(.tb) {
    display: block;
    position: absolute;
    visibility: hidden;
    opacity: 0;
}
.tz:not(.tb) {
    display: none !important;
}
.ne {
    display: -webkit-inline-box;
    display: inline-flex;
}
.nt {
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    line-height: 0;
    border: none;
    background: transparent;
}
.nt::before {
    content: "";
    position: absolute;
    left: 6px;
    top: 6px;
    background: #f9fafc;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    -webkit-transition: background 0.25s ease;
    transition: background 0.25s ease;
}
.nt:focus {
    outline: none;
}
.nt.tb::before {
    background: #3d5afe;
}
.nt:hover::before {
    background: #e9ecf8;
}
.r .nt::before {
    background: #575e86;
}
.r .nt.tb::before {
    background: #3d5afe;
}
.r .nt:hover::before {
    background: #9298b8;
}
.nn {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: flex-start;
    justify-content: flex-start;
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-shrink: 0;
    margin-bottom: 0 !important;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.nn::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: #e9ecf8;
    z-index: -1;
}
.nr {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    font-weight: 500;
    padding: 8px 24px;
    color: #9298b8;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #e9ecf8;
    cursor: pointer;
}
.nr.tb {
    color: #3d5afe;
    border-bottom-color: #3d5afe;
}
.ni {
    padding-top: 24px;
    padding-bottom: 24px;
}
.ni:not(.tb) {
    display: none !important;
}
.r .nn::after {
    background-color: #9298b8;
}
.r .nr {
    color: #9298b8;
    border-bottom-color: #9298b8;
}
.r .nr.tb {
    color: #fff;
    border-bottom-color: #fff;
}
.ns {
    margin-top: -24px;
}
.ns:last-of-type {
    margin-bottom: -24px;
}
.ns:not(:last-of-type) {
    margin-bottom: 24px;
}
.no {
    position: relative;
    padding-top: 24px;
    padding-bottom: 24px;
}
.no:not(:last-of-type)::before {
    content: "";
    display: block;
    position: absolute;
    top: 43px;
    left: 3px;
    width: 2px;
    height: calc(100% - 8px - 8px);
    background: #e9ecf8;
}
.r .no:not(:last-of-type)::before {
    background: #575e86;
}
.nu {
    margin-left: 32px;
}
.na {
    position: relative;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
}
.na::before {
    content: "";
    display: block;
    position: absolute;
    top: 7px;
    left: -32px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #3d5afe;
}
.r .na::before {
    background: #e9ecf8;
}
@media (min-width: 641px) {
    .ns {
        margin-top: -32px;
    }
    .ns:last-of-type {
        margin-bottom: -32px;
    }
    .ns:not(:last-of-type) {
        margin-bottom: 32px;
    }
    .no {
        padding-top: 32px;
        padding-bottom: 32px;
        width: 50%;
    }
    .no:not(:last-of-type)::before {
        top: 51px;
        left: -1px;
    }
    .no:nth-child(odd) {
        left: 50%;
    }
    .no:nth-child(odd) .na::before {
        left: -36px;
    }
    .no:nth-child(even) {
        text-align: right;
    }
    .no:nth-child(even):not(:last-of-type)::before {
        left: auto;
        right: -1px;
    }
    .no:nth-child(even) .nu {
        margin-left: 0;
        margin-right: 32px;
    }
    .no:nth-child(even) .na {
        position: relative;
    }
    .no:nth-child(even) .na::before {
        left: auto;
        right: -36px;
    }
}
.nf {
    margin-top: -24px;
}
.nf:last-of-type {
    margin-bottom: -24px;
}
.nf:not(:last-of-type) {
    margin-bottom: 24px;
}
.nf .split-item {
    padding-top: 24px;
    padding-bottom: 24px;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
}
.nf .split-item .nl {
    position: relative;
    width: 100%;
}
.nf .split-item .nl img,
.nf .split-item .nl svg,
.nf .split-item .nl video {
    width: auto;
    height: auto;
    max-width: 100%;
    overflow: visible;
}
.nf .split-item .nl.nc img,
.nf .split-item .nl.nc svg,
.nf .split-item .nl.nc video {
    width: 100%;
}
@media (max-width: 640px) {
    .nf .split-item .nh {
        margin-bottom: 40px;
    }
    .nf.np .split-item .nh {
        -webkit-box-ordinal-group: 2;
        order: 1;
        margin-bottom: 0 !important;
    }
    .nf.np .split-item .nl {
        margin-bottom: 40px;
    }
}
@media (min-width: 641px) {
    .nf {
        margin-top: -40px;
    }
    .nf:last-of-type {
        margin-bottom: -40px;
    }
    .nf:not(:last-of-type) {
        margin-bottom: 40px;
    }
    .nf .split-item {
        padding-top: 40px;
        padding-bottom: 40px;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
        flex-wrap: nowrap;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: flex-start;
        justify-content: flex-start;
    }
    .nf .split-item .nh {
        flex-shrink: 0;
        width: 396px;
        margin-bottom: 0;
        margin-right: 64px;
    }
    .nf .split-item .nl {
        min-width: 436px;
    }
    .nf:not(.nd) .split-item:nth-child(even),
    .nf.nd .split-item:nth-child(odd) {
        -webkit-box-pack: end;
        justify-content: flex-end;
    }
    .nf:not(.nd) .split-item:nth-child(even) .nh,
    .nf.nd .split-item:nth-child(odd) .nh {
        -webkit-box-ordinal-group: 2;
        order: 1;
        margin-left: 64px;
        margin-right: 0;
    }
    .nf:not(.nd) .split-item:nth-child(odd) .nl img,
    .nf:not(.nd) .split-item:nth-child(odd) .nl svg,
    .nf:not(.nd) .split-item:nth-child(odd) .nl video,
    .nf.nd .split-item:nth-child(even) .nl img,
    .nf.nd .split-item:nth-child(even) .nl svg,
    .nf.nd .split-item:nth-child(even) .nl video {
        margin-left: auto;
    }
    .nf.nv .split-item {
        -webkit-box-align: flex-start;
        align-items: flex-start;
    }
}
@media (min-width: 821px) {
    .tcontainern .nf .split-item .nh {
        width: 488px;
    }
    .tcontainern .nf .split-item .nl {
        min-width: 528px;
    }
}
.tiles-wrap {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    margin-right: -12px;
    margin-left: -12px;
    margin-top: -12px;
}
.tiles-wrap:last-of-type {
    margin-bottom: -12px;
}
.tiles-wrap:not(:last-of-type) {
    margin-bottom: 12px;
}
.tiles-wrap.nm::after {
    content: "";
    flex-basis: 330px;
    max-width: 330px;
    box-sizing: content-box;
    padding-left: 12px;
    padding-right: 12px;
    height: 0;
}
.ng {
    flex-basis: 330px;
    max-width: 330px;
    box-sizing: content-box;
    padding: 12px;
}
.ng * {
    box-sizing: border-box;
}
.ny {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    height: 100%;
    padding: 32px 24px;
}
.nb {
    position: absolute !important;
    top: 0;
    width: 100%;
    z-index: 10 !important;
}
.nb + .nw .nx:first-of-type {
    padding-top: 80px;
}
.nb .nk {
    margin-right: 32px;
}
.n_ {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    height: 80px;
}
.nj {
    -webkit-box-flex: 1;
    flex-grow: 1;
}
.nj .nq {
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
}
.nj ul {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
    white-space: nowrap;
    margin-bottom: 0;
}
.nj ul:first-of-type {
    flex-wrap: wrap;
}
.nj li + .bbuttonw {
    margin-left: 32px;
}
.nj a:not(.tbuttonn) {
    display: block;
    color: #575e86;
    text-decoration: underline;
    font-weight: 500;
    text-transform: uppercase;
    padding: 0 32px;
}
.nj a:not(.tbuttonn):hover,
.nj a:not(.tbuttonn):active {
    color: #2e3458;
    text-decoration: none;
}
.r .nj a:not(.tbuttonn) {
    color: #f9fafc;
    text-decoration: underline;
}
.r .nj a:not(.tbuttonn):hover,
.r .nj a:not(.tbuttonn):active {
    color: #fff;
    text-decoration: none;
}
.nj a.tbuttonn {
    margin-left: 32px;
}
.nz:first-of-type {
    -webkit-box-flex: 1;
    flex-grow: 1;
    -webkit-box-pack: end;
    justify-content: flex-end;
}
.re {
    -webkit-box-pack: end;
    justify-content: flex-end;
}
.re + .re {
    -webkit-box-flex: 0;
    flex-grow: 0;
}
.th {
    display: none;
}
@media (max-width: 640px) {
    .th {
        display: block;
    }
    .th + .nj {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        z-index: 9999;
        background: #292e4e;
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        -webkit-transition: max-height 0.25s ease-in-out, opacity 0.15s;
        transition: max-height 0.25s ease-in-out, opacity 0.15s;
    }
    .th + .nj.tb {
        opacity: 1;
    }
    .th + .nj .nq {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
        padding: 24px;
    }
    .th + .nj ul {
        display: block;
        text-align: center;
    }
    .th + .nj ul a:not(.tbuttonn) {
        display: -webkit-inline-box;
        display: inline-flex;
        color: #9298b8;
        padding-top: 12px;
        padding-bottom: 12px;
    }
    .th + .nj ul a:not(.tbuttonn):hover,
    .th + .nj ul a:not(.tbuttonn):active {
        color: #e9ecf8;
    }
    .th + .nj a.tbuttonn {
        margin-left: 0;
        margin-top: 12px;
        margin-bottom: 12px;
    }
}
.rt {
    background: #f9fafc;
}
.rt .rn {
    max-width: 1440px;
    margin: 0 auto;
    box-shadow: 0 20px 48px rgba(46, 52, 88, 0.1);
}
.rt .rn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: -10;
}
.rn {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    min-height: 100vh;
}
.nw {
    -webkit-box-flex: 1;
    flex: 1 0 auto;
}
.rr {
    position: relative;
}
.rr::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2e3458;
    z-index: -3;
}
.ri {
    padding: 48px 0;
}
.rs {
    padding-bottom: 12px;
}
.ro {
    padding-top: 12px;
}
.ru {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -24px;
    margin-left: -24px;
    margin-top: -12px;
}
.ru:last-of-type {
    margin-bottom: 28px;
}
.ru:not(:last-of-type) {
    margin-bottom: 12px;
}
.ra {
    -webkit-box-flex: 1;
    flex-grow: 1;
    flex-basis: 160px;
    box-sizing: content-box;
    padding: 12px 24px;
}
.ra * {
    box-sizing: border-box;
}
.ra ul li:not(:last-child) {
    margin-bottom: 4px;
}
.ra a {
    color: #575e86;
    text-decoration: none;
}
.ra a:hover,
.ra a:active {
    color: #2e3458;
}
.r .ra a {
    color: #9298b8;
    text-decoration: none;
}
.r .ra a:hover,
.r .ra a:active {
    color: #e9ecf8;
}
.rf {
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
    color: #2e3458;
    text-transform: uppercase;
    margin-bottom: 8px;
}
.r .rf {
    color: #e9ecf8;
}
.rl a,
.rc a {
    display: -webkit-box;
    display: flex;
}
.rl ul,
.rc ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    white-space: nowrap;
    margin-bottom: 0;
}
.rl ul {
    margin-right: -16px;
    margin-left: -16px;
}
.rl li {
    padding: 0 8px;
}
.rl a {
    padding: 8px;
}
.rl a svg {
    fill: #3d5afe;
    -webkit-transition: fill 0.15s ease;
    transition: fill 0.15s ease;
}
.rl a:hover svg {
    fill: #7085fe;
}
.rc {
    margin-right: -12px;
    margin-left: -12px;
}
.rc a {
    color: #575e86;
    text-decoration: none;
    padding: 0 12px;
}
.rc a:hover,
.rc a:active {
    color: #2e3458;
}
.r .rc a {
    color: #9298b8;
    text-decoration: none;
}
.r .rc a:hover,
.r .rc a:active {
    color: #e9ecf8;
}
@media (max-width: 640px) {
    .rs > *:not(:last-child),
    .ro > *:not(:last-child) {
        margin-bottom: 24px;
    }
}
@media (min-width: 641px) {
    .ri {
        padding: 64px 0;
    }
    .rs.rh,
    .ro.rh {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
    }
    .rs.rp > *:first-child,
    .ro.rp > *:first-child {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }
}
.rd {
    position: relative;
    padding-top: 48px;
    padding-bottom: 48px;
}
.rv {
    padding-bottom: 48px;
}
@media (min-width: 641px) {
    .rd {
        padding-top: 80px;
        padding-bottom: 80px;
    }
    .rv {
        padding-bottom: 80px;
    }
}
.rm > .rg + .ry,
.rm > .ry + .rg {
    margin-top: 48px;
}
.rm > .ry > a {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    vertical-align: top;
}
@media (max-width: 640px) {
    .rb .nf .split-item .nh {
        margin-bottom: 48px;
    }
    .rb .nf.np .split-item .nl {
        margin-bottom: 48px;
    }
}
@media (min-width: 641px) {
    .rm > .rg + .ry,
    .rm > .ry + .rg {
        margin-top: 64px;
    }
}
.rw .tiles-wrap {
    margin-right: -16px;
    margin-left: -16px;
    margin-top: -16px;
}
.rw .tiles-wrap:last-of-type {
    margin-bottom: -16px;
}
.rw .tiles-wrap:not(:last-of-type) {
    margin-bottom: 16px;
}
.rw .tiles-wrap.nm::after {
    flex-basis: 317px;
    max-width: 317px;
    padding-left: 16px;
    padding-right: 16px;
}
.rw .ng {
    flex-basis: 317px;
    max-width: 317px;
    padding: 16px;
}
.rw .ny {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}
@media (min-width: 641px) {
    .rw .tiles-wrap {
        margin-right: -32px;
        margin-left: -32px;
        margin-top: -32px;
    }
    .rw .tiles-wrap:last-of-type {
        margin-bottom: -32px;
    }
    .rw .tiles-wrap:not(:last-of-type) {
        margin-bottom: 32px;
    }
    .rw .tiles-wrap.nm::after {
        padding-left: 32px;
        padding-right: 32px;
    }
    .rw .ng {
        padding: 32px;
    }
}
.rx .nn {
    margin-top: -10px;
    margin-bottom: -10px !important;
    -webkit-box-pack: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow-x: initial;
    padding-bottom: 48px;
}
.rx .nn::after {
    content: normal;
}
.rx .nr {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    opacity: 0.48;
    border: initial;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
    -webkit-transition: opacity 0.15s ease;
    transition: opacity 0.15s ease;
}
.rx .nr:hover {
    opacity: 0.72;
}
.rx .nr.tb {
    opacity: 1;
}
.rx .ni {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
    visibility: hidden;
    will-change: transform;
}
.rx .ni.tb {
    opacity: 1;
    visibility: visible;
    -webkit-animation: tabsPanelIn 0.5s ease 0.15s both;
    animation: tabsPanelIn 0.5s ease 0.15s both;
}
@-webkit-keyframes tabsPanelIn {
    0% {
        opacity: 0;
        -webkit-transform: translateY(12px);
        transform: translateY(12px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
@keyframes tabsPanelIn {
    0% {
        opacity: 0;
        -webkit-transform: translateY(12px);
        transform: translateY(12px);
    }
    to {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}
.rk .tiles-wrap.nm::after {
    flex-basis: 344px;
    max-width: 344px;
}
.rk .ng {
    flex-basis: 344px;
    max-width: 344px;
}
.rk .ny {
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
}
.r_ {
    -webkit-box-flex: 1;
    flex-grow: 1;
    width: 100%;
}
ul.rj li {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 12px;
}
.rq,
.rz {
    margin-bottom: 48px;
}
.rz {
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}
.rz input {
    width: 100%;
}
.rz .ie {
    position: absolute;
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;
    color: #9298b8;
    margin-top: 8px;
    --thumb-size: 36px;
}
.r .ie {
    color: #e9ecf8;
}
@media (min-width: 641px) {
    .rq,
    .rz {
        margin-top: -32px;
        margin-bottom: 64px;
    }
}
.it .tiles-wrap.nm::after {
    flex-basis: 344px;
    max-width: 344px;
}
.it .ng {
    flex-basis: 344px;
    max-width: 344px;
}
.it .ny {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 24px;
    padding-right: 24px;
}
.in {
    -webkit-box-flex: 1;
    flex-grow: 1;
}
.ir {
    display: -webkit-inline-box;
    display: inline-flex;
    flex-wrap: wrap;
    -webkit-box-align: center;
    align-items: center;
}
.ii .tiles-wrap {
    margin-right: -16px;
    margin-left: -16px;
    margin-top: -16px;
}
.ii .tiles-wrap:last-of-type {
    margin-bottom: -16px;
}
.ii .tiles-wrap:not(:last-of-type) {
    margin-bottom: 16px;
}
.ii .tiles-wrap.nm::after {
    flex-basis: 296px;
    max-width: 296px;
    padding-left: 16px;
    padding-right: 16px;
}
.ii .ng {
    flex-basis: 296px;
    max-width: 296px;
    padding: 16px;
}
.ii .ny {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}
@media (min-width: 641px) {
    .ii .rv {
        padding-bottom: 80px;
    }
    .ii .tiles-wrap {
        margin-right: -32px;
        margin-left: -32px;
        margin-top: -32px;
    }
    .ii .tiles-wrap:last-of-type {
        margin-bottom: -32px;
    }
    .ii .tiles-wrap:not(:last-of-type) {
        margin-bottom: 32px;
    }
    .ii .tiles-wrap.nm::after {
        padding-left: 32px;
        padding-right: 32px;
    }
    .ii .ng {
        padding: 32px;
    }
}
.is .rd {
    padding-top: 48px;
    padding-bottom: 48px;
}
.io {
    margin-bottom: 40px;
}
@media (min-width: 641px) {
    .is .rd {
        padding-top: 64px;
        padding-bottom: 64px;
    }
    .iu {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
    }
    .iu .io {
        margin-bottom: 0;
        margin-right: 48px;
    }
}
.ia .rd {
    padding-top: 26px;
    padding-bottom: 26px;
}
.if ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    margin: 0;
}
.if li {
    padding: 12px 28px;
}
@media (min-width: 641px) {
    .ia .if ul {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        flex-direction: row;
    }
}
.il .rv {
    padding-bottom: 32px;
}
.il .ny {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}
@media (min-width: 641px) {
    .il .rv {
        padding-bottom: 48px;
    }
}
.ic {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
[class*="illustration-"] {
    position: relative;
}
[class*="illustration-"]::after {
    pointer-events: none;
}
[class*="illustration-element-"]::after {
    z-index: -1;
}
[class*="illustration-section-"]::after {
    z-index: -2;
}
.tillustration-section-n::after {
    content: "";
    position: absolute;
    width: 100%;
    max-width: 100%;
    height: 870px;
    /* background-image: url(@/assets/img/home/illustration-section-01.svg); */
    background-repeat: no-repeat;
    background-size: auto;
    left: 50%;
    top: 0;
    background-position: center top;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
}
.ih {
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(233, 236, 248, 0)), to(#e9ecf8));
    background: linear-gradient(to bottom, rgba(233, 236, 248, 0) 0%, #e9ecf8 100%);
    z-index: 0 !important;
}
.tbuttonn {
    background: linear-gradient(0deg, #e9ecf8 1px, #fff 2px, #fff 100%);
    background-origin: border-box;
    box-shadow: 0 2px 4px rgba(46, 52, 88, 0.32), 0 8px 12px rgba(46, 52, 88, 0.24);
}
.tbuttonn:hover {
    background: linear-gradient(0deg, #e5e9f7 1px, #fcfcfc 2px, #fcfcfc 100%);
    background-origin: border-box;
}
.tbuttonn:active {
    box-shadow: 0 1px 2px rgba(46, 52, 88, 0.32), 0 8px 12px rgba(46, 52, 88, 0.24);
}
.ubuttona {
    background: linear-gradient(0deg, #2c3255 1px, #2e3458 2px, #575e86 100%);
    background-origin: border-box;
}
.ubuttona:hover {
    background: linear-gradient(0deg, #2e3458 1px, #30365b 2px, #596089 100%);
    background-origin: border-box;
}
.fbuttonl {
    background: linear-gradient(0deg, #0a2ffe 1px, #3d5afe 2px, #7085fe 100%);
    background-origin: border-box;
}
.fbuttonl:hover {
    background: linear-gradient(0deg, #0f33fe 1px, #425efe 2px, #7589fe 100%);
    background-origin: border-box;
}
.cbuttonh {
    background: linear-gradient(0deg, #00a993 1px, #00c2a9 2px, #5cd8c8 100%);
    background-origin: border-box;
}
.cbuttonh:hover {
    background: linear-gradient(0deg, #00ae97 1px, #00c7ad 2px, #60d9c9 100%);
    background-origin: border-box;
}
.k .ta {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #9298b8), to(#a5aac4));
    background: linear-gradient(0deg, #9298b8 0, #a5aac4 100%);
}
.k input:checked + .ta {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #3d5afe), to(#7085fe));
    background: linear-gradient(0deg, #3d5afe 0, #7085fe 100%);
}
@media (min-width: 641px) {
    .t .no:nth-child(even) .ip.greveal-y {
        -webkit-transform: translateX(-20px);
        transform: translateX(-20px);
    }
    .t .no:nth-child(odd) .ip.greveal-y {
        -webkit-transform: translateX(20px);
        transform: translateX(20px);
    }
    .t.n .no .ip.is-revealed {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}
.ng .id {
    box-shadow: 0 8px 16px rgba(46, 52, 88, 0.16);
}
.ng .id.ny {
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #f9fafc), to(#fff));
    background: linear-gradient(0deg, #f9fafc 0, #fff 100%);
}
.iv .nl {
    position: relative;
}
.im {
    display: -webkit-inline-box;
    display: inline-flex;
    border-radius: 50%;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #3d5afe), to(#7085fe));
    background: linear-gradient(0deg, #3d5afe 0, #7085fe 100%);
    box-shadow: 0 2px 4px rgba(46, 52, 88, 0.12);
}
.ig {
    display: -webkit-inline-box;
    display: inline-flex;
    border-radius: 50%;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #e9ecf8), to(#fff));
    background: linear-gradient(0deg, #e9ecf8 0, #fff 100%);
    box-shadow: 0 2px 4px rgba(46, 52, 88, 0.32);
}
.rk.iy {
    position: relative;
    background-color: transparent;
    z-index: auto;
}
.rk.iy::before {
    content: "";
    width: 100%;
    height: 300px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #2e3458;
    z-index: -3;
}
.ib {
    position: relative;
}
.ib::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 1px;
    background: #e9ecf8;
}
.ib::after {
    max-width: 88px;
}
.iw {
    color: #575e86;
}
.iw,
.ix {
    font-weight: 500;
}
ul.rj {
    position: relative;
}
ul.rj::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 1px;
    background: #e9ecf8;
}
ul.rj::after {
    background: rgba(233, 236, 248, 0.32);
}
ul.rj li {
    margin-bottom: 0;
    padding: 14px 0;
    position: relative;
}
ul.rj li::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 1px;
    background: #e9ecf8;
}
ul.rj li::after {
    content: "";
    display: block;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M5%2011h14v2H5z%22%20fill%3D%22%239298B8%22%20fill-rule%3D%22nonzero%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    -webkit-box-ordinal-group: 0;
    order: -1;
}
ul.rj li.ik::after {
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill-rule%3D%22nonzero%22%20fill%3D%22none%22%3E%3Ccircle%20fill%3D%22%2300C2A9%22%20cx%3D%2212%22%20cy%3D%2212%22%20r%3D%2212%22%2F%3E%3Cpath%20fill%3D%22%23fff%22%20d%3D%22M10.5%2012.267l-2.5-1.6-1%201.066L10.5%2016%2017%209.067%2016%208z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
.i_ img {
    border-radius: 50%;
}
.ij {
    color: #2e3458;
}
.iq {
    padding-top: 20px;
}
.iz {
    color: #3d5afe;
}
.iz a {
    color: #3d5afe;
    text-decoration: none;
}
.iz a:hover {
    color: #7085fe;
}
.ii .se {
    color: #7085fe;
}
.is.iy {
    position: relative;
    background-color: transparent;
    z-index: auto;
}
.is.iy::before {
    content: "";
    width: 100%;
    height: 50%;
    position: absolute;
    left: 0;
    bottom: 0;
    background: #2e3458;
    z-index: -3;
}
.is .j {
    border-color: transparent;
    border-radius: 2px;
}
.st {
    padding-left: 16px;
    padding-right: 16px;
    background-color: #3d5afe;
    /* background-image: url(@/assets/img/home/cta-illustration.svg); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right top;
}
@media (min-width: 641px) {
    .is .j {
        min-width: 280px;
    }
    .st {
        padding-left: 48px;
        padding-right: 48px;
    }
}
.sn {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}
.sr {
    list-style: none;
    padding: 0;
}
.sr li {
    margin: 0;
}
.si {
    text-align: left;
}
.ss {
    text-align: center;
}
.so {
    text-align: right;
}
.su {
    font-weight: 400;
}
.sa {
    font-weight: 500;
}
.sf {
    font-weight: 600;
}
.sl {
    font-weight: 700;
}
.sc {
    color: #2e3458;
}
.sh {
    color: #575e86;
}
.sp {
    color: #9298b8;
}
.sd {
    color: #3d5afe;
}
.sv {
    color: #00c2a9;
}
.sm {
    color: #ff6f66;
}
.sg {
    color: #ffcd6e;
}
.sy {
    color: #00c2a9;
}
.r .sc {
    color: #fff;
}
.r .sh {
    color: #f9fafc;
}
.r .sp {
    color: #e9ecf8;
}
.sb {
    text-transform: uppercase;
}
.sw {
    width: 100%;
}
.sx {
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: calc(100% + 96px);
    max-width: 100vw;
}
.sk {
    position: relative;
    background-color: #2e3458;
    z-index: 0;
}
.id {
    box-shadow: 0 12px 32px rgba(46, 52, 88, 0.24);
}
img.id {
    border-radius: 2px;
}
.s_ {
    position: relative;
}
.s_::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 1px;
    background: #e9ecf8;
}
.sj {
    position: relative;
}
.sj::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 1px;
    background: #e9ecf8;
}
.r .s_ {
    position: relative;
}
.r .s_::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 1px;
    background: rgba(87, 94, 134, 0.24);
}
.r .sj {
    position: relative;
}
.r .sj::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: block;
    height: 1px;
    background: rgba(87, 94, 134, 0.24);
}
.sq {
    text-align: center;
}
.sq img,
.sq svg,
.sq video {
    margin-left: auto;
    margin-right: auto;
}
.sq .vbuttonm {
    -webkit-box-pack: center;
    justify-content: center;
}
.sz {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}
.sz.oe {
    padding-bottom: 75%;
}
.sz iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.ot {
    margin: 0;
}
.on {
    margin-top: 0;
}
.or {
    margin-right: 0;
}
.oi {
    margin-bottom: 0;
}
.os {
    margin-left: 0;
}
.oo {
    margin: 4px;
}
.ou {
    margin-top: 4px;
}
.oa {
    margin-right: 4px;
}
.of {
    margin-bottom: 4px;
}
.ol {
    margin-left: 4px;
}
.oc {
    margin: 8px;
}
.oh {
    margin-top: 8px;
}
.op {
    margin-right: 8px;
}
.od {
    margin-bottom: 8px;
}
.ov {
    margin-left: 8px;
}
.om {
    margin: 12px;
}
.og {
    margin-top: 12px;
}
.oy {
    margin-right: 12px;
}
.ob {
    margin-bottom: 12px;
}
.ow {
    margin-left: 12px;
}
.ox {
    margin: 16px;
}
.ok {
    margin-top: 16px;
}
.o_ {
    margin-right: 16px;
}
.oj {
    margin-bottom: 16px;
}
.oq {
    margin-left: 16px;
}
.oz {
    margin: 24px;
}
.ue {
    margin-top: 24px;
}
.ut {
    margin-right: 24px;
}
.un {
    margin-bottom: 24px;
}
.ur {
    margin-left: 24px;
}
.ui {
    margin: 32px;
}
.us {
    margin-top: 32px;
}
.uo {
    margin-right: 32px;
}
.uu {
    margin-bottom: 32px;
}
.ua {
    margin-left: 32px;
}
.uf {
    padding: 0;
}
.ul {
    padding-top: 0;
}
.uc {
    padding-right: 0;
}
.uh {
    padding-bottom: 0;
}
.up {
    padding-left: 0;
}
.ud {
    padding: 4px;
}
.uv {
    padding-top: 4px;
}
.um {
    padding-right: 4px;
}
.ug {
    padding-bottom: 4px;
}
.uy {
    padding-left: 4px;
}
.ub {
    padding: 8px;
}
.uw {
    padding-top: 8px;
}
.ux {
    padding-right: 8px;
}
.uk {
    padding-bottom: 8px;
}
.u_ {
    padding-left: 8px;
}
.uj {
    padding: 12px;
}
.uq {
    padding-top: 12px;
}
.uz {
    padding-right: 12px;
}
.ae {
    padding-bottom: 12px;
}
.at {
    padding-left: 12px;
}
.an {
    padding: 16px;
}
.ar {
    padding-top: 16px;
}
.ai {
    padding-right: 16px;
}
.as {
    padding-bottom: 16px;
}
.ao {
    padding-left: 16px;
}
.au {
    padding: 24px;
}
.aa {
    padding-top: 24px;
}
.af {
    padding-right: 24px;
}
.al {
    padding-bottom: 24px;
}
.ac {
    padding-left: 24px;
}
.ah {
    padding: 32px;
}
.ap {
    padding-top: 32px;
}
.ad {
    padding-right: 32px;
}
.av {
    padding-bottom: 32px;
}
.am {
    padding-left: 32px;
}
.ag {
    padding-top: 4px;
}
.ay {
    padding-top: 8px;
}
.ab {
    padding-top: 12px;
}
.aw {
    padding-top: 16px;
}
.ax {
    padding-top: 24px;
}
.ak {
    padding-top: 32px;
}
.a_ {
    padding-top: 48px;
}
.aj {
    padding-top: 64px;
}
@media (max-width: 640px) {
    .aq {
        text-align: left;
    }
    .az {
        text-align: center;
    }
    .fe {
        text-align: right;
    }
    .ft {
        text-align: center;
    }
    .ft img,
    .ft svg,
    .ft video {
        margin-left: auto;
        margin-right: auto;
    }
    .ft .vbuttonm {
        -webkit-box-pack: center;
        justify-content: center;
    }
    .fn {
        padding-top: 4px;
    }
    .fr {
        padding-top: 8px;
    }
    .fi {
        padding-top: 12px;
    }
    .fs {
        padding-top: 16px;
    }
    .fo {
        padding-top: 24px;
    }
    .fu {
        padding-top: 32px;
    }
    .fa {
        padding-top: 48px;
    }
    .ff {
        padding-top: 64px;
    }
}
@media (min-width: 641px) {
    .fl {
        text-align: left;
    }
    .fc {
        text-align: center;
    }
    .fh {
        text-align: right;
    }
    .fp {
        text-align: center;
    }
    .fp img,
    .fp svg,
    .fp video {
        margin-left: auto;
        margin-right: auto;
    }
    .fp .vbuttonm {
        -webkit-box-pack: center;
        justify-content: center;
    }
}

#light-buttonn{
    color: #7085fe;
    text-decoration: none;
    text-transform: none;
    font-weight: bold;

}

.soon, .new{
    margin-left: 6px;
    line-height:100%;
    display: inline-block;
    padding: 4px 6px;
    background: #90A4AE;
    color: #fff;
    font-size: 9px;
    text-transform: uppercase;
    border-radius: 8px;
}

.new{
    background: #E91E63;
}

.ot{
    line-height: 140%;
}

</style>


